import React from "react"
import Helmet from "react-helmet"
import Layout, { Section, H2 } from "../../components/layout"
import Hero from "../../components/hero"
import Footer from "../../components/footer"
import backgroundImage from '../../images/articles/changes-to-the-heritage-act-2017.jpg';
import styled from "styled-components"

const ArticleInfo = styled.div`
    display: flex;
`

const DatePosted = styled.p`
    font-weight: bold;
`

const ReadTime = styled.p`
    margin-left: 1rem;
`

const Underline = styled.span`
    text-decoration: underline;
`

const IndexPage = () => {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Polis Legal - Changes to the Heritage Act 2017 - What you need to know</title>
                <meta name="description" content="Recent changes passed by Parliament to the Heritage Act 2017 are due to commence on 1 February 2024, and most notably include a new exclusion determination process for State government infrastructure projects." />
                <meta name="robots" content="index, follow" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Changes to the Heritage Act 2017 - What you need to know" />
                <meta property="og:url" content="//polislegal.com.au/articles/changes-to-the-heritage-act-2017/" />
                <meta property="og:image" content={`//polislegal.com.au${backgroundImage}`} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="757" />
                <meta property="og:image:alt" content />
                <meta property="og:locale" content="en_GB" />
                <meta property="article:published_time" content="2023-05-04T09:00:00+00:00" />
            </Helmet>
            <Hero background={backgroundImage} title="Changes to the Heritage Act 2017 - What you need to know" />
            <Section>
                <ArticleInfo>
                    <DatePosted>04 May 2023</DatePosted>
                    <ReadTime>6 min read</ReadTime>
                </ArticleInfo>

                <p>The Heritage Amendment Act 2023 (<strong>Amendment Act</strong>) was passed by both houses of Parliament in late March 2023 and will commence on 1 February 2024 (unless proclaimed to commence earlier). The Amendment Act makes various changes to the Heritage Act 2017 (<strong>Heritage Act</strong>), most notably a new exclusion determination process for State government infrastructure projects.</p>
                <H2>Summary of changes</H2>
                <p>Many of changes that the Amendment Act will make to the Heritage Act are sensible and practical updates, and key ones are summarised in the table below. The new 'exclusion determination' process is also discussed further below. </p>
                <p>Transitional provisions apply to the changes regarding nominations, permit applications, archaeological consents and other matters, which should be carefully reviewed for applications which have been lodged prior to the commencement of the new provisions. </p>
                <H2>Key changes</H2>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p>Owner consent</p>
                            </td>
                            <td>
                                <p>The Amendment Act establishes new provisions for various application, determination and notification processes throughout the Heritage Act. The effect of these is that, if an application, determination or notification relates to only part of a place or object, the applicant / notifier is only required to obtain the written consent of / notify the owner or government asset manager of that part of the place or object.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Permit exemption for subdivisions and consolidations</p>
                            </td>
                            <td>
                                <p>New section 91 of the Heritage Act will create an exemption from requiring a heritage permit to subdivide or consolidate land, if:</p>
                                <ul>
                                    <li><p>the subdivision or consolidation is in accordance with a planning permit; and </p></li>
                                    <li><p>the application for the planning permit was referred to the Executive Director (<strong>ED</strong>) as a determining referral authority under the Planning and Environment Act 1987 (<strong>PE Act</strong>).</p></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>RFI process for amendment applications </p>
                            </td>
                            <td>
                                <p>New section 105A of the Heritage Act will introduce an RFI process for applications to amend heritage permits. This is a sensible approach, as the Heritage Act currently does not contain such a process. A response to an RFI issued by the ED must be given within 20 days, however extensions of time to respond to the RFI may be sought. </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Minor amendments to heritage permits </p>
                            </td>
                            <td>
                                <p>New section 105B of the Heritage Act will allow a permit holder to request the ED to make a minor amendment to a heritage permit. The ED must determine the request within 20 business days and no appeal or review rights apply to the determination. Without a definition, it is unclear what would constitute a 'minor amendment'. The ED <Underline>must</Underline> refuse the request if the proposed amendment:</p>
                                <ul>
                                    <li><p>is not minor in the context of the works or activities authorised by the permit, and instead requires an application for amendment to the permit to be made; or</p></li>
                                    <li><p>is so substantial that a new permit application is necessary.</p></li>
                                </ul>
                                <p>The ED <Underline>may</Underline> refuse the request if the proposed amendment may result in harm to the cultural heritage significance of the registered place or registered object in relation to which the permit was issued. </p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Heritage Inventory removals </p>
                            </td>
                            <td>
                                <p>New section 119 of the Heritage Act will introduce additional considerations to apply in determining whether to remove a site from the Heritage Inventory. Presently, the Heritage Act provides that the Heritage Council may remove an archaeological site from the Heritage Inventory if the ED determines that the site has low archaeological value. The new provision will retain this, and include the following additional considerations: </p>
                                <ul>
                                    <li>
                                        <p>if the ED determines that:</p>
                                        <ul>
                                            <li><p>inclusion of the site in the Heritage Register as a registered place is sufficient to protect and conserve the site; and</p></li>
                                            <li><p>inclusion of the site in the Heritage Inventory is no longer necessary; or</p></li>
                                        </ul>
                                    </li>
                                    <li><p>as part of the amalgamation of 2 or more sites in the Heritage Inventory; or </p></li>
                                    <li><p>if the site is not an archaeological site. </p></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Archaeological consent provisions</p>
                            </td>
                            <td>
                                <ul>
                                    <li><p>New section 123A of the Heritage Act will introduce a process whereby a proponent can seek an exemption from requiring a consent (eg, to disturb a site in the Heritage Inventory) issued under section 124 of the Heritage Act. The ED may grant an exemption from requiring a consent if the ED considers that any damage or disturbance to the site or artefact by the proposed works or activities would be 'negligible'. </p></li>
                                    <li><p>New section 124A of the Heritage Act will introduce a process whereby a proponent may apply to amend a consent granted under section 124. This is a sensible approach, as the Heritage Act currently does not contain such a process. The amendment application must be refused if the ED considers the requested amendment is so substantial that a new consent is necessary.</p></li>
                                    <li><p>New section 124B of the Heritage Act will introduce an RFI process for new and amendment consent applications.</p></li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Public availability requirements</p>
                            </td>
                            <td>
                                <p>New Part 12A of the Heritage Act will introduce public availability requirements for Heritage Act processes. These largely mirror those already set out in the PE Act, and include: </p>
                                <ul>
                                    <li><p>making permit applications electronically available for 14 days in accordance with the public availability requirements; and</p></li>
                                    <li><p>making the Heritage Register and Heritage Inventory publicly available. </p></li>
                                </ul>
                                <p>New provisions will also provide for Heritage Council hearings to be held by audio link or audio visual link, again mirroring the PE Act provisions regarding this. </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <H2>New exclusion determination process</H2>
                <p>Currently, the Heritage Act allows any person to nominate a place for inclusion in the Heritage Register. The ED must then determine whether or not to accept the nomination. If the nomination is accepted, that recommendation is given by the ED to the Heritage Council, who then conducts a public process and makes a final determination on registration. There is no ability to make an application for review of the ED's decision to accept a nomination. </p>
                <p>The Amendment Act will introduce a new Division 2A of Part 3 of the Heritage Act. The new provisions will enable a 'prescribed person or body, or a person or body included in a prescribed class' to make an application to the ED for an exclusion determination, being a determination that a place <Underline>not</Underline> be included in the Heritage Register.</p>
                <p>A summary of the process is set out below:</p>
                <ul>
                    <li><p>The application must include reasons why the place or object should not be included in the Heritage Register, being reasons based on assessment criteria published by the Heritage Council. </p></li>
                    <li><p>The ED can make the exclusion determination, make it only in part, or refuse to make it. </p></li>
                    <li><p>There is an RFI process, however there is no specified timeframe for the ED to decide on the application for an exclusion determination. </p></li>
                    <li><p>There is no process for notice, advertising or submissions on the application for an exclusion determination. </p></li>
                    <li><p>The ED may decide to make the exclusion determination (or make it in part) if the ED is satisfied that the place or object (or part) has no reasonable prospect of inclusion in the Heritage Register. If the ED decides to make the exclusion determination, notice of this decision must be placed on the agency website for at least 14 days and published by newspaper. </p></li>
                    <li><p>The ED's decision to make or refuse to make an exclusion determination may be reviewed by a person who has 'a real and substantial interest in the place or object or the part of the place or object'. The application for review must be made to the Heritage Council within 28 days of the ED's decision. </p></li>
                    <li>
                        <p>The Heritage Council may, within 40 days: </p>
                        <ul>
                            <li><p>affirm the ED's decision;</p></li>
                            <li><p>set aside the ED's decision and make another decision in substitution for it; or</p></li>
                            <li><p>set aside the ED's decision and remit the matter to the ED for reconsideration in accordance with any directions or recommendations.</p></li>
                        </ul>
                    </li>
                    <li><p>If the ED refuses to make an exclusion determination, and all review rights regarding that refusal are exhausted, then new section 34A provides that the ED is taken to have accepted a nomination for the place or object's registration in the Heritage Register. The language of the provision suggests that a nomination for the place or object must have been made (by any person) in order for it to be taken as 'accepted' by the ED; however, section 23 of the Amendment Act suggests that an ED's decision to refuse to issue an exclusion determination is deemed to be a nomination for registration of the place, even in the absence of someone actually making such a nomination. </p></li>
                    <li><p>Transitional provisions apply such that, if there is a nomination for registration prior to the commencement of the Amendment Act, and then (after commencement) an exclusion determination application is made, then the nomination is taken to be withdrawn so that the ED must determine the exclusion determination and not the nomination. </p></li>
                </ul>
                <p>In addition to waiting to see the assessment criteria for consideration of exclusion determinations, we also await regulations which define who the prescribed persons and class of persons are. According to the second reading speech of the Amendment Act, the exclusion determination process will only be available for 'government agencies tasked with delivering major transport projects'. </p>
                <p>The Hansard debates discuss the benefits of the new exclusion determination process - it allows for proactive assessment, and it 'frontloads' such assessment in order to give certainty to projects, as it will avoid nominations being received at later stages of a project and the major disruptions and delays that come with that.</p>
                <p>This then begs the question - why is it only available to government agencies for major infrastructure projects? </p>
                <p>This fulsome and early assessment process, based on published criteria and with application for review options available, is a beneficial pathway for any project. The real risk of a nomination causing disruption and delay part-way through a project's planning is not just one faced by government agencies. For example, where housing - and in particular social and affordable housing - is seen as critical social infrastructure that is currently in undersupply and which is largely provided by private entities, the exclusion determination process could provide real benefits to ensuring upfront assessment and certainty towards delivery. </p>
            </Section>
            <Footer />
        </Layout>
    )
}

export default IndexPage
